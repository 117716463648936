import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledCareNeedsContainer = styled.div`
	width: 100%;
	padding: 30px;
	background-color: white;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	float: left;
	margin-right: 16px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const StyledAbility = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const StyledTraitHeading = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${colors.primary.primary};
`;

export const StyledTraitValue = styled.p`
	font-size: 0.75rem;
	color: ${colors.primary.primary};

	width: 200px;
	text-align: right;
`;
