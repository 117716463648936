import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TransparentButton } from '../../../../../../corev2/Buttons';

import { TextStyled } from '../../../../../../corev2/Text';
import PageHeading from '../../../../core/components/PageHeading';
import AuthCodeForm from '../../../components/AuthCodeForm';

import { useAuth } from '../../../../core/hooks/useAuth';
import { usePlatformOnboarding } from '../../../hooks/usePlatformOnboarding';
import { useRegisterCareProMutation } from '../../../api/mutations/useRegisterCareProMutation';

import { useVerifyCareProPhoneMutation } from '../../../api/mutations/useVerifyCareProPhoneMutation';

import { authRoutes } from '../../../routes/constants';

import { StyledAuthCodePage } from './styles';

const AuthCodePage = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const [serverError, setServerError] = useState('');

	const { platformOnboardingState, mutatePlatformOnboardingState, resetPlatformOnboardingState } =
		usePlatformOnboarding();
	const { loginCarePro } = useAuth();
	const registercareProMutation = useRegisterCareProMutation();

	const verifyCareProPhoneMutation = useVerifyCareProPhoneMutation();

	const authCodeHandler = async (values) => {
		console.log('values', values);
		const authCode = values.authCode;

		// remove any trailing space before and after the code
		values.code = authCode.trim();

		try {
			setLoading(true);
			const { token, carePro } = await registercareProMutation.mutateAsync({
				email: platformOnboardingState.accountInfo.data.email,
				password: platformOnboardingState.accountInfo.data.password,
				firstName: platformOnboardingState.personalInfo.data.firstName,
				lastName: platformOnboardingState.personalInfo.data.lastName,
				phone: platformOnboardingState.accountInfo.data.phone,
				displayName: platformOnboardingState.personalInfo.data.displayName,
				authCode: authCode,
				gender: platformOnboardingState.personalInfo.data.gender,
			});

			await loginCarePro(token);

			mutatePlatformOnboardingState('authCode', {
				...platformOnboardingState.authCode,
				data: {
					code: values.code,
				},
				isCompleted: true,
			});

			setLoading(false);
			resetPlatformOnboardingState();
		} catch (error) {
			console.error('Auth code verification / Registration failed', error);
			setServerError(error[0].message);
			setLoading(false);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.platform.accountInfo);
	};

	const reSendCode = async () => {
		try {
			await verifyCareProPhoneMutation.mutateAsync({
				phone: platformOnboardingState.accountInfo.data.phone,
				email: platformOnboardingState.accountInfo.data.email,
			});
		} catch (err) {
			console.error('Resend code failed');
		}
	};
	return (
		<StyledAuthCodePage>
			<PageHeading>Auth Code</PageHeading>
			<AuthCodeForm
				initialValues={{
					authCode: platformOnboardingState.authCode.data.authCode,
				}}
				authCodeHandler={authCodeHandler}
				serverError={serverError}
				loading={loading}
			/>
			<TextStyled
				onClick={() => reSendCode()}
				style={{
					textDecoration: 'underline',
					padding: '10px 0',
					cursor: 'pointer',
				}}
			>
				Resend Code
			</TextStyled>
			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledAuthCodePage>
	);
};

export default AuthCodePage;
