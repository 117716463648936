import React from 'react';

import { Slider } from '../../../../../corev2/Forms';
import Tags from '../../../../../corev2/Tags';

import {
	StyledJobFilter,
	StyledTagsWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
} from './styles';

const JobFilter = (props) => {
	const { stats, changeCareProStatusParam } = props;

	const options = Object.values(stats).map((stat, index) => {
		return {
			name: `${stat.title} (${stat.stat})`,
			selected: stat.isActive,
			value: stat.param,
		};
	});

	return (
		<StyledJobFilter>
			<StyledFilterLabelWrapper>
				<StyledLabel>Care Pros</StyledLabel>
			</StyledFilterLabelWrapper>

			<StyledTagsWrapper>
				<Tags options={options} selectHandler={changeCareProStatusParam} />
			</StyledTagsWrapper>

			<Slider
				label='Budget'
				minAllow={5}
				maxAllow={50}
				step={5}
				minValue={5}
				maxValue={50}
				handler={() => {}}
			/>
		</StyledJobFilter>
	);
};

export default JobFilter;
