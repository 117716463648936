import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { receiveUserDetails } from 'reducers/admin';

import AdminChat from './AdminChat';
import AdminDisputesHandler from './ManageDisputes/AdminDisputesHandler';
import CarePlanSearch from './CarePlanSearch';
import PaymentTable from './PaymentTable';
import ProviderSearch from './ProviderSearch';
import UserModel from './UserModel';
import AllShifts from './AllShifts';
import OnboardFlow from './OnboardFlow';
import AgencySettings from './AgencySettings';
import ManageDocuments from './ManageDocuments';
import ManageDoc from './ManageDoc';
import ProviderPage from './ProviderPage';
import ShiftPage from './ShiftPage';
import PaymentPage from './PaymentPage';
import PatientSearch from './PatientSearch';
import ClientPage from './ClientPage';
import CareProProfile from './CarePro/CareProHandler';
import CareProDocs from './CarePro/ManageCareProDocs';

import Notification, { NotificationContainer } from './core/NotificationHandler';
import notifications from './core/notification';

import FormRoutes from './Forms/routes';
import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';
import RegistryFormDataHandler from './ModelDataViews/RegistryFormDataHandler';

import { RowFlex } from '../styled/Basic';

const CustomRow = styled(RowFlex)`
	overflow: hidden;
`;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const AdminRoutes = (props) => {
	const queryClient = new QueryClient();

	const { userDetail, receiveUserDetails } = props;

	// useEffect(() => {
	// 	Notification(notifications.test);
	// }, []);
	return (
		<QueryClientProvider client={queryClient}>
			<NotificationContainer />
			<CustomRow flex>
				<BasicOverlayWrapper
					status={userDetail.status}
					appear
					handleExitClick={() =>
						receiveUserDetails({ status: false, user: null, type: null })
					}
				>
					<UserModel
						userDetail={userDetail}
						handleExitClick={() =>
							receiveUserDetails({ status: false, user: null, type: null })
						}
					/>
				</BasicOverlayWrapper>
				<Switch>
					<Route
						path='/registry'
						exact
						children={(props) => {
							return <PaymentTable {...props} />;
						}}
					/>
					<Route
						path='/registry/shifts'
						exact
						children={(props) => {
							return <AllShifts {...props} />;
						}}
					/>
					<Route
						path='/registry/shift/:shift_id'
						exact
						children={(props) => {
							return <ShiftPage {...props} />;
						}}
					/>
					<Route
						path='/registry/payment/:payment_id'
						exact
						children={(props) => {
							return <PaymentPage {...props} />;
						}}
					/>

					<Route
						path='/registry/messages/:conversation_id'
						children={(props) => {
							return <AdminChat {...props} />;
						}}
					/>
					<Route
						path='/registry/messages'
						children={(props) => {
							return <AdminChat {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/disputes'
						children={(props) => {
							return <p>Show the right screen</p>;
						}}
					/>
					<Route
						exact
						path='/registry/carepro/:careProId'
						children={(props) => {
							return <CareProProfile {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/careproDocs/:careProId'
						children={(props) => {
							return <CareProDocs {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/formData/:formId'
						children={(props) => {
							return <RegistryFormDataHandler {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/carepros'
						children={(props) => {
							return <ProviderSearch {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/clients/:clientId'
						children={(props) => {
							return <ClientPage {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/clients'
						children={(props) => {
							return <PatientSearch {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/careplans'
						children={(props) => {
							return <CarePlanSearch {...props} />;
						}}
					/>

					<Route
						exact
						path='/registry/onboard'
						children={(props) => {
							return <OnboardFlow {...props} />;
						}}
					/>
					<Route
						exact
						path='/registry/settings'
						children={(props) => {
							return <AgencySettings {...props} />;
						}}
					/>
					<Route
						path='/registry/settings/:tab'
						children={(props) => {
							return <AgencySettings {...props} />;
						}}
					/>
					<Route
						path='/registry/documents'
						children={(props) => {
							return <ManageDocuments {...props} />;
						}}
					/>
					<Route
						path='/registry/documents/:type'
						children={(props) => {
							return <ManageDocuments {...props} />;
						}}
					/>

					<Route
						path='/registry/document/:id'
						children={(props) => {
							return <ManageDoc {...props} />;
						}}
					/>
					<FormRoutes />
				</Switch>
			</CustomRow>
		</QueryClientProvider>
	);
};

const mapStateToProps = ({ admin }) => {
	return {
		userDetail: admin.userDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		receiveUserDetails: (params) => dispatch(receiveUserDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoutes);
