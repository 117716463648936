import React from 'react';
import { useHistory } from 'react-router-dom';

import { Hearts } from 'react-loader-spinner';

import Colors from 'colors';

import { LoaderSection } from '../../../../../../corev2/FormGen/styles';

import { useFetchCarePlanCareNeeds } from '../../../api/queries/useFetchCarePlanForms';
import useUpdateCarePlanFormAnswers from '../../../api/mutations/useUpdateCarePlanFormAnswers';
import { useHandleUpdateCarePlanPlatformOnboard } from '../../../api/mutations/useHandleUpdateCarePlanPlatformOnboard';

import FormGen from '../../../../../../corev2/FormGen';
import DescriptivePage from '../../../../../../corev2/DescriptivePage';
import Text from '../../../../../../corev2/Text';

import { carePlanRoutes } from '../../../routes/constants';
import { TransparentButton } from '../../../../../../corev2/Buttons';

import { coreRoutes } from '../../../../core/routes/constants';
import { useAuth } from '../../../../core/hooks/useAuth';
import { useCarePlan } from '../../../hooks/useCarePlan';

const CareNeedsPage = () => {
	const history = useHistory();

	const { data, isLoading } = useFetchCarePlanCareNeeds();
	const updateCarePlanFormAnswers = useUpdateCarePlanFormAnswers();
	const handleUpdateCarePlanPlatformOnboard = useHandleUpdateCarePlanPlatformOnboard();

	const { carePlanState, mutateCarePlanState, resetCarePlanState } = useCarePlan();
	const { authUser, refreshAuthUser, modifyAuthUser } = useAuth();

	if (isLoading) {
		return (
			<LoaderSection>
				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Hearts color={Colors.primary.primary} height={100} width={100} />
				</div>
			</LoaderSection>
		);
	}

	if (data === undefined || data === null) {
		return (
			<LoaderSection>
				<Text>Something went wrong</Text>
			</LoaderSection>
		);
	}

	const handleSubmit = async (values) => {
		console.log('submitting form', values);
		// do whatever you need to move forward

		// you can assume that this can be called after
		try {
			const updateVal = await updateCarePlanFormAnswers.mutateAsync({
				formId: data.id,
				answers: values,
			});

			const carePlanData = await handleUpdateCarePlanPlatformOnboard.mutateAsync({
				carePlanId: authUser.carePlan.id,
			});

			modifyAuthUser('carePlan', carePlanData);

			mutateCarePlanState('careNeeds', {
				...carePlanState.careNeeds,
				isCompleted: true,
			});

			refreshAuthUser();
			resetCarePlanState();

			history.push(coreRoutes.documents);
		} catch (err) {
			console.error(err);
		}
	};

	const backButtonHandler = () => {
		history.push(carePlanRoutes.onboarding.logistics);
	};
	const getDescriptionText = () => {
		return <Text size='large'>Let us know what type of care you need</Text>;
	};

	return (
		<>
			<DescriptivePage heading='Care Plan Needs' descriptionContent={getDescriptionText()}>
				<FormGen showTitle={false} formData={data} handleSubmit={handleSubmit} />
			</DescriptivePage>
			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</>
	);
};

export default CareNeedsPage;
