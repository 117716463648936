import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RelationshipPage from './RelationshipPage';
import InfoPage from './InfoPage';
import PersonalPage from './PersonalPage';
import LogisticsPage from './LogisticsPage';
import CareNeedsPage from './CareNeedsPage';

import Steps from '../../../../../corev2/Steps';

import { CarePlanProvider } from '../../context/CarePlanContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useCarePlan } from '../../hooks/useCarePlan';

import { coreRoutes } from '../../../core/routes/constants';

import { StyledCarePlanOnboardingPage, StyledStepsContainer } from './styles';

const CarePlanContent = ({ children }) => {
	const history = useHistory();
	const { carePlanState } = useCarePlan();
	const { hasComppletedPlatformOnboarding, hasDocumentsApproval } = useAuth();

	useLayoutEffect(() => {
		const steps = Object.values(carePlanState);
		const currentStep = steps.find((step) => !step.isCompleted);
		if (currentStep) {
			history.push(currentStep.route);
		} else {
			if (hasComppletedPlatformOnboarding && !hasDocumentsApproval) {
				history.push(coreRoutes.documents);
			}
		}
	}, [carePlanState, history]);

	console.log('carePlanState', carePlanState);

	return (
		<StyledCarePlanOnboardingPage>
			<StyledStepsContainer>
				<Steps steps={carePlanState} />
			</StyledStepsContainer>
			{children}
		</StyledCarePlanOnboardingPage>
	);
};

const CarePlanOnboardingPage = (props) => {
	return (
		<CarePlanProvider>
			<CarePlanContent {...props} />
		</CarePlanProvider>
	);
};

export { RelationshipPage, InfoPage, PersonalPage, LogisticsPage, CareNeedsPage };
export default CarePlanOnboardingPage;
