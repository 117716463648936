import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton, SecondaryButton } from '../../Buttons';
import Rating from '../../Rating';

import { functions } from '../../utils';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledRatingContainer,
	StyledLocation,
	StyledGender,
	StyledDescriptionContainer,
	StyledDescription,
	StyledButtonContainer,
} from './styles';

const CareProTile = (props) => {
	const {
		id,
		rating,
		displayName,
		professionalStatement,
		hourlyRate,
		gender,
		careType,
		customButtonText,
		onCustomButtonClick,
		viewCareProHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer>
				<FontAwesomeIcon size='2x' icon={faUser} />
			</StyledAvatarContainer>

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{displayName}</StyledName>
						<StyledRatingContainer>
							<Rating rating={rating} />
						</StyledRatingContainer>
						<StyledLocation>Lake Raymondport</StyledLocation>
						<StyledGender>
							{functions.capitalizeFirstLetter(gender)} |{' '}
							{careType === 'hourly' ? 'Hourly' : 'Live In'}
						</StyledGender>
					</StyledPersonalInfo>
					<StyledRate>
						<StyledHour>{hourlyRate ? `$${hourlyRate} / hr` : 'No rate'}</StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				<StyledLocation>
					{
						// this should be location
					}
				</StyledLocation>
				<StyledDescriptionContainer>
					<StyledDescription>{professionalStatement}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					{customButtonText && (
						<PrimaryButton size='small' onClick={() => onCustomButtonClick(id)}>
							{customButtonText}
						</PrimaryButton>
					)}

					{/* <SecondaryButton size='small' onClick={() => viewCareProHandler(id)}>
						View
					</SecondaryButton> */}
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default CareProTile;
