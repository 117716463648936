export const coreRoutes = {
	root: '/care-pro',
	auth: '/care-pro/auth',
	jobPosts: '/care-pro/job-posts',
	chat: '/care-pro/chat',
	profiles: '/care-pro/profiles',
	blockedTimes: '/care-pro/blocked-times',
	documents: '/care-pro/documents',
	dashboard: '/care-pro/dashboard',
	shifts: '/care-pro/shifts',
	skills: '/care-pro/skills',
	payments: '/care-pro/payments',
	tos: '/care-pro/terms-of-service',
	privacyPolicy: '/care-pro/privacy-policy',
};
