import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobFilter = styled.div`
	background: white;
	padding: 20px;

	color: ${colors.primary.primary};

	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	display: flex;
	flex-direction: column;

	gap: 8px;
`;

export const StyledTagsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	gap: 10px;
`;

export const StyledFilterLabelWrapper = styled.div`
	display: flex;
	min-width: 170px;

	text-align: left;
`;

export const StyledLabel = styled.span`
	font-size: 16px;
	font-weight: bold;
	line-height: 1.4;

	color: ${colors.primary.primary};
`;
