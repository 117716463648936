export const genereteTraitsFromFormData = (carePlan) => {
	const extractTraits = (info, heading) => {
		if (!info || !info.form_answers) return [];
		return info.form_answers.map((answer) => ({
			trait: `${heading} - ${answer.trait.label}`,
			value: answer.trait_value.replace(/^"(.*)"$/, '$1'),
		}));
	};

	const receiverTraits = extractTraits(carePlan.receiver_info, 'Receiver Info');
	const locationTraits = extractTraits(carePlan.location_info, 'Location');
	const careNeedsTraits = extractTraits(carePlan.needs_info, 'Care Needs');

	return [...receiverTraits, ...locationTraits, ...careNeedsTraits];
};
