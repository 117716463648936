export const notifications = {
	auth: {
		login: `You have successfully logged in`,
		logout: `You have successfully logged out`,
		omboarding: {},
		paymemntMethod: 'Please add a payment method.',
	},
	carePlan: {},
	jobPosts: {
		created: `Job post created successfully.`,
		scheduleNeeded: 'You need to set a schedule first',
		scheduleAdded: `Schedule added for job post.`,
		careProInvited: `CarePro invited to job post.`,
		careProOffered: `Offer sent to CarePro.`,
	},
	dashboard: {
		accountInfo: {
			updated: `Account info updated.`,
		},
		paymentMethods: {
			paymentMethodAdded: `Payment method added.`,
			paymentMethodDeleted: `Payment method deleted.`,
			defaultPaymentMethodChanged: `Default payment method changed.`,
		},
	},
};
