import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	faBriefcase,
	faEnvelope,
	faMousePointer,
	faPercentage,
	faHandsHelping,
	faStopCircle,
} from '@fortawesome/free-solid-svg-icons';

import DescriptivePage from '../../../../../corev2/DescriptivePage';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import Text from '../../../../../corev2/Text';

import JobPost from '../../components/JobPost';
import JobPostsCategory from '../../components/JobPostsCategory';

import { jobPostsRoutes } from '../../routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchCarePlanJobPostsQuery } from '../../api/queries/useFetchCarePlanJobPostsQuery';
import { useGenerateJobPostsContent } from '../../hooks/useGenerateJobPostsContent';

import { StyledJobPostsContainer, StyledSideContent, StyledJobPostsText } from './styles';

const ICONS_MAP = {
	pending: faBriefcase,
	invited: faEnvelope,
	applied: faMousePointer,
	offered: faPercentage,
	active: faHandsHelping,
	inactive: faStopCircle,
};

const JobPostsPage = () => {
	const [jobPosts, setJobPosts] = useState([]);
	const { jobPostsCategory } = useParams();

	const history = useHistory();

	const { authUser } = useAuth();

	const { data } = useFetchCarePlanJobPostsQuery(authUser.carePlan.id);

	const { filteredJobPosts, stats } = useGenerateJobPostsContent(jobPosts);

	useEffect(() => {
		if (data) {
			setJobPosts(data);
		}
	}, [data]);

	const goToJobPostsPage = () => {
		history.push(jobPostsRoutes.home);
	};

	const goToCreateJobPostPage = () => {
		history.push(jobPostsRoutes.create);
	};

	const goToJobPostPage = (jobPostId) => {
		history.push(jobPostsRoutes.jobPost(jobPostId));
	};

	const goToJobPostsCategoryPage = (jobPostsCategory) => {
		history.push(jobPostsRoutes.jobPostsCategory(jobPostsCategory));
	};

	const getJobJobPostsDescriptions = () => {
		let text = (
			<>
				<Text size='large'>
					Manage your job posts. Select a category to view specific job listings or create
					a new job post using the button below.
				</Text>
			</>
		);

		if (jobPostsCategory === 'pending') {
			text = (
				<Text size='large'>
					These jobs are pending. Care Pros can browse and apply. Review applications to
					proceed with hiring.
				</Text>
			);
		}
		if (jobPostsCategory === 'invited') {
			text = (
				<Text size='large'>
					You’ve invited specific Care Pros to apply. Monitor responses and select
					suitable candidates.
				</Text>
			);
		}
		if (jobPostsCategory === 'applied') {
			text = (
				<Text size='large'>
					Providers have applied to these jobs. Review applications and choose the right
					fit for your needs.
				</Text>
			);
		}
		if (jobPostsCategory === 'offered') {
			text = (
				<Text size='large'>
					You’ve extended job offers. Await responses from Care Pros or follow up if
					needed.
				</Text>
			);
		}
		if (jobPostsCategory === 'active') {
			text = (
				<Text size='large'>
					These jobs are currently active. Manage tasks, assignments, and ongoing job
					details from this section.
				</Text>
			);
		}
		if (jobPostsCategory === 'inactive') {
			text = (
				<Text size='large'>
					These jobs are closed, canceled, or rejecected by Care Pros. Review past work
					history or create a new job post.
				</Text>
			);
		}

		return (
			<StyledSideContent>
				{text}

				{jobPostsCategory && (
					<>
						<PrimaryButton onClick={goToJobPostsPage} size='small'>
							Categories
						</PrimaryButton>
					</>
				)}

				<PrimaryButton onClick={goToCreateJobPostPage} size='small'>
					+ Create Job Post
				</PrimaryButton>
			</StyledSideContent>
		);
	};

	return (
		<DescriptivePage
			heading={`Job Posts${jobPostsCategory ? ` - ${stats[jobPostsCategory].title}` : ''}`}
			descriptionContent={getJobJobPostsDescriptions()}
		>
			<StyledJobPostsContainer>
				{!jobPostsCategory &&
					Object.keys(stats).map((categoryKey) => {
						const category = stats[categoryKey];
						return (
							<JobPostsCategory
								key={categoryKey}
								icon={ICONS_MAP[categoryKey]}
								title={category.title}
								description={`Browse ${category.title.toLowerCase()} jobs.`}
								goToJobPostsCategoryPage={() =>
									goToJobPostsCategoryPage(category.slug)
								}
								stat={category.stat}
							/>
						);
					})}

				{jobPostsCategory &&
					filteredJobPosts.map((jobPost) => {
						return (
							<JobPost
								key={jobPost.id}
								title={jobPost.title}
								description={jobPost.description}
								goToJobPostPage={() => goToJobPostPage(jobPost.id)}
							/>
						);
					})}

				{jobPostsCategory && filteredJobPosts.length === 0 && (
					<StyledJobPostsText> No job posts in this category. </StyledJobPostsText>
				)}
			</StyledJobPostsContainer>
		</DescriptivePage>
	);
};

export default JobPostsPage;
