import React, { useCallback } from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
	FormContainer,
	Input,
	Label,
	TextArea,
	Slider,
	ErrorMessage,
} from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../../components/FormWrapper';
import InputContainer from '../../components/InputContainer';

import createJobPostValidationSchema from './validation';

const CreateJobPostForm = (props) => {
	const { serverError, initialValues, createJobPostHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => createJobPostHandler(values),
		validationSchema: toFormikValidationSchema(createJobPostValidationSchema),
		enableReinitialize: true,
	});

	const handleSliderChange = useCallback(
		({ minValue, maxValue }) => {
			if (formik.values.budgetMin !== minValue) {
				formik.setFieldValue('budgetMin', minValue);
			}
			if (formik.values.budgetMax !== maxValue) {
				formik.setFieldValue('budgetMax', maxValue);
			}
		},
		[formik.values.budgetMin, formik.values.budgetMax, formik.setFieldValue]
	);

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Title </Label>

					<Input
						name='title'
						type='text'
						onChange={formik.handleChange}
						placeholder='Title'
						value={formik.values.title}
					/>

					{formik.errors.title && <ErrorMessage> {formik.errors.title} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Description </Label>

					<TextArea
						name='description'
						onChange={formik.handleChange}
						placeholder='Description'
						value={formik.values.description}
					/>

					{formik.errors.description && (
						<ErrorMessage> {formik.errors.description} </ErrorMessage>
					)}
				</InputContainer>

				{/* <InputContainer>
					<Slider
						label='Budget'
						minAllow={5}
						maxAllow={50}
						step={5}
						minValue={formik.values.minValue}
						maxValue={formik.values.maxValue}
						handler={handleSliderChange}
					/>
				</InputContainer> */}

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Create Job Post
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default CreateJobPostForm;
