import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledJobSchedulePage = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
	width: 100%;
	max-width: 980px;
	margin: auto;

	@media ${mediaQueries.mobileM} {
		padding: 20px 0;
	}
`;

export const StyledContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	max-width: 980px;
	margin: 0 auto;
	padding: 20px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledCalendarHeader = styled.div`
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
`;

export const StyledHeadingContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 768px) {
		padding: 5px;
	}
`;

export const StyledNavContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	margin-bottom: 10px;
`;

export const StyledCalendarheading = styled.h2`
	color: ${colors.primary.primary};
	font-size: 1.2rem;
	font-weight: bold;
	margin: 0;
	padding: 0 15px;
	text-align: center;
`;

export const StyledCalendarSelectGroup = styled.div`
	width: 100px;
	line-height: 40px;
`;

export const StyledBlueSelect = styled.div`
	color: ${colors.primary.primary};
	line-height: 40px;
	padding: 5px 12px;
	min-height: 42px;
	font-size: 27px;

	::placeholder {
		color: ${colors.forms.placeholderColor};
		opacity: 1;
		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;
