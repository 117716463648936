import React from 'react';
import { Route, Switch } from 'react-router-dom';

import JobPostsPage from '../pages/JobPostsPage';
import JobPostPage from '../pages/JobPostPage';
import CreateJobPostPage from '../pages/CreateJobPostPage';
import JobSchedulePage from '../pages/JobSchedulePage';
import JobPostOfferPage from '../pages/JobPostOfferPage';
import ViewOfferPage from '../pages/ViewOfferPage';

import { jobPostsRoutes } from './constants';

const JobPostsRoutes = () => {
	return (
		<Switch>
			<Route path={jobPostsRoutes.create}>
				<CreateJobPostPage />
			</Route>

			<Route path={jobPostsRoutes.jobPostsCategory(':jobPostsCategory')} exact>
				<JobPostsPage />
			</Route>

			<Route path={jobPostsRoutes.home} exact>
				<JobPostsPage />
			</Route>

			<Route path={jobPostsRoutes.jobPost(':jobPostId')} exact>
				<JobPostPage />
			</Route>

			<Route path={jobPostsRoutes.jobPostSchedule(':jobPostId')}>
				<JobSchedulePage />
			</Route>

			<Route path={jobPostsRoutes.viewOfferPage(':jobPostId', ':jobOfferId')}>
				<ViewOfferPage />
			</Route>

			<Route path={jobPostsRoutes.jobPostOffer(':jobPostId', ':careProId')} exact>
				<JobPostOfferPage />
			</Route>
		</Switch>
	);
};

export default JobPostsRoutes;
