import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckCareProSessionQuery } from '../../api/queries/useCheckCareProSessionQuery';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

export const AuthContext = createContext();

const initialAuthState = {
	appIsLoading: true,
	isAuthenticated: false,
	isRegistryApproved: false,
	hasSetProfessionalStatement: false,
	hasCompletedStripeOnboarding: false,
	isStripeVerified: false,
	hasCompletedPlatformOnboarding: false,
	pendingStripeDocumentRequirements: false,
	completedDocumentApproval: false,
	authCarePro: null,
	statusUpdate: 'Pending Registry Approval',
};

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState(initialAuthState);
	const history = useHistory();

	const { data, isLoading, refetch: refreshAuthCarePro } = useCheckCareProSessionQuery();

	// Update authentication and onboarding states
	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);

		if (!isLoading && data) {
			const { careProRegistryStatus, professional_statement, stripeStatus } = data;

			// Update whether the user is authenticated based on token existence
			setAuthState({
				isAuthenticated: !!token,

				appIsLoading: false,

				// Determine if registry approval is granted
				isRegistryApproved: careProRegistryStatus?.registry_approval || false,

				// Check if the user has set a professional statement
				hasSetProfessionalStatement: professional_statement !== null,

				// Verify if Stripe onboarding is completed
				isStripeVerified: stripeStatus?.status === 'verified',

				// Check if Stripe onboarding is not unverified or empty
				hasCompletedStripeOnboarding:
					stripeStatus?.status !== 'unverified' && stripeStatus?.status !== '',

				// Check if the platform onboarding is completed
				hasCompletedPlatformOnboarding: careProRegistryStatus?.platform_onboard || false,

				// Determine if there are pending Stripe document requirements
				pendingStripeDocumentRequirements: (
					stripeStatus?.stripe_data?.currently_due || []
				).some((req) => req.endsWith('.document')),

				// Check if all required documents are approved
				completedDocumentApproval: careProRegistryStatus?.documents_approval || false,

				// Store the CarePro data if a valid token exists
				authCarePro: token ? data : null,

				// Set the status update based on registry approval
				statusUpdate: careProRegistryStatus?.registry_approval
					? 'Approved'
					: 'Pending Registry Approval',
			});
		} else if (!token) {
			// Clear authentication state if no token is found
			setAuthState((prevState) => ({
				...prevState,
				isAuthenticated: false,
				authCarePro: null,
				appIsLoading: false,
			}));
			setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
		}
	}, [data, isLoading]);

	// Authentication methods
	const loginCarePro = (token) => {
		// Store the token in localStorage and update state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, token);
		refreshAuthCarePro();
	};

	const logoutCarePro = () => {
		// Clear localStorage and reset authentication state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, null);
		setAuthState({ ...initialAuthState, appIsLoading: false });
	};

	const value = {
		...authState,
		loginCarePro,
		logoutCarePro,
		refreshAuthCarePro,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
