import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import BackButton from '../../../../../corev2/BackButton';
import { CareProTile } from '../../../../../corev2/Tiles';
import Loader from '../../../../../corev2/Loader';
import Text from '../../../../../corev2/Text';

import PageHeading from '../../../core/components/PageHeading';

import JobAction from '../../components/JobAction';
import JobFilter from '../../components/JobFilter';

import { coreRoutes } from '../../../core/routes/constants';
import { jobPostsRoutes } from '../../routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { JobPostProvider } from '../../context/JobPostContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';
import { useRenderCarePros } from '../../hooks/useRenderCarePros';

import { useSearchCareProsByCarePlanQuery } from '../../api/queries/useSearchCareProsByCarePlanQuery';
import { useInviteProviderToJobPost } from '../../api/mutations/useInviteProviderToJobPost';
import { useContactCareProMutation } from '../../api/mutations/useContactCareProMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import {
	StyledJobPostPage,
	StyledPageHeadingContainer,
	StyledJobSection,
	StyledActionsContainer,
	StyledCareProsContainer,
	StyledCategoryDescription,
} from './styles';

const JobPostContent = (props) => {
	const history = useHistory();
	const location = useLocation();
	const { jobPostId } = useParams();
	const searchParams = new URLSearchParams(location.search);
	const status = searchParams.get('status') || 'search';

	const { authUser, hasDefaultPaymentMethod } = useAuth();
	const { jobPost, isLoadingJobPost, updateJobPost, hasSetSchedule } = useJobPost();

	const { data: carePros, isLoading: isLoadingCarePros } = useSearchCareProsByCarePlanQuery(
		authUser.carePlan.id,
		1
	);

	const { stats, carePros: filteredCarePros } = useRenderCarePros(jobPost, carePros);

	const inviteCareProToJobPostMutation = useInviteProviderToJobPost();
	const contactCarePro = useContactCareProMutation();

	const goToCarePlanPage = () => {
		history.push(coreRoutes.carePlan);
	};

	const goToSchedulePage = () => {
		history.push(jobPostsRoutes.jobPostSchedule(jobPostId));
	};

	const changeCareProStatusParam = (name, status) => {
		searchParams.set('status', status);
		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const inviteCareProToJobPostHandler = async (careProId) => {
		if (!hasDefaultPaymentMethod) {
			return Notification(notifications.auth.paymemntMethod);
		}

		if (!hasSetSchedule) {
			return Notification(notifications.jobPosts.scheduleNeeded);
		}

		try {
			await inviteCareProToJobPostMutation.mutateAsync({ jobPostId, careProId });
			updateJobPost();

			Notification(notifications.jobPosts.careProInvited);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const goToOfferCareProPage = async (careProId) => {
		if (!hasSetSchedule) {
			alert('You need to set a schedule first.');
			return;
		}

		history.push(jobPostsRoutes.jobPostOffer(jobPost.id, careProId));
	};

	const goToViewOfferPage = async (careProId) => {
		const job = jobPost.jobs.find((job) => job.provider.id === careProId);

		history.push(jobPostsRoutes.viewOfferPage(jobPost.id, job.id));
	};

	const chatWithCareProHandler = async (careProId) => {
		try {
			const data = await contactCarePro.mutateAsync({ careProId, userId: authUser.id });

			history.push(coreRoutes.chat);
		} catch (error) {
			console.error('Inviting care pro to job post failed', error);
		}
	};

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	const goToJobPostsPage = () => {
		history.push(jobPostsRoutes.home);
	};

	const getHandler = (status) => {
		switch (status) {
			case 'Invite':
				return inviteCareProToJobPostHandler;
			case 'Offer':
				return goToOfferCareProPage;
			case 'View Offer':
				return goToViewOfferPage;
			case 'Chat':
				return chatWithCareProHandler;
			default:
				return null;
		}
	};

	const getCareProCategoryDescriptions = () => {
		let description;

		switch (status) {
			case 'search':
				description = (
					<Text size='large'>
						Browse all available Care Pros in your registry. Use filters to find
						qualified candidates and send invitations directly.
					</Text>
				);
				break;

			case 'invited':
				description = (
					<Text size='large'>
						Care Pros you’ve invited to apply for the job. Monitor responses and follow
						up if needed to ensure timely hiring.
					</Text>
				);
				break;

			case 'applied':
				description = (
					<Text size='large'>
						Care Pros who have applied for your job. Review their applications,
						qualifications, and experience before proceeding with interviews or offers.
					</Text>
				);
				break;

			case 'offered':
				description = (
					<Text size='large'>
						Candidates you’ve extended job offers to. Wait for their responses or
						contact them to expedite the hiring process.
					</Text>
				);
				break;

			case 'active':
				description = (
					<Text size='large'>
						Care Pros currently working on your job. Manage assignments, monitor
						progress, and communicate for seamless job execution.
					</Text>
				);
				break;

			case 'inactive':
				description = (
					<Text size='large'>
						Care Pros who declined offers, completed assignments, or whose contracts
						ended. Review past work history for future consideration or new job
						postings.
					</Text>
				);
				break;

			default:
				description = (
					<Text size='large'>
						Manage Care Pros related to your job posts. Select a category to view Care
						Pros or create a new job post using the button below.
					</Text>
				);
		}

		return description;
	};

	if (isLoadingJobPost) {
		return <Loader />;
	}

	return (
		<>
			<StyledPageHeadingContainer>
				<BackButton withText clickHandler={goToJobPostsPage} />
				<PageHeading>{jobPost.title}</PageHeading>
			</StyledPageHeadingContainer>

			<StyledJobSection>
				<StyledActionsContainer>
					<JobFilter stats={stats} changeCareProStatusParam={changeCareProStatusParam} />

					<JobAction
						title='Schedule'
						description='Manage your schedule to let CarePros know when you need care.'
						goToPage={goToSchedulePage}
					/>

					<JobAction
						title='Care Plan'
						description='Manage your Care Plan to let CarePros know what care you need.'
						goToPage={goToCarePlanPage}
					/>
				</StyledActionsContainer>

				<StyledCareProsContainer>
					<StyledCategoryDescription>
						{getCareProCategoryDescriptions()}
					</StyledCategoryDescription>

					{filteredCarePros &&
						filteredCarePros.map((carePro) => (
							<CareProTile
								key={carePro.id}
								id={carePro.id}
								displayName={carePro.displayName}
								rating={carePro.averageReview}
								hourlyRate={carePro.hourly_rate}
								professionalStatement={carePro.professional_statement}
								gender={carePro.gender}
								careType={carePro.care_type}
								customButtonText={carePro.customText}
								onCustomButtonClick={getHandler(carePro.customText)}
								viewCareProHandler={viewCareProHandler}
								careProInfo={carePro}
							/>
						))}

					{filteredCarePros && filteredCarePros.length === 0 && (
						<Text>No Care Pros found.</Text>
					)}
				</StyledCareProsContainer>
			</StyledJobSection>
		</>
	);
};

const JobPostPage = () => {
	return (
		<JobPostProvider>
			<StyledJobPostPage>
				<JobPostContent />
			</StyledJobPostPage>
		</JobPostProvider>
	);
};

export default JobPostPage;
